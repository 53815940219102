import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

const initialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            capacity: {
                $between: [0, 100],
            },
            type: {
                $containsi: '',
            },
            $or: [
                {
                    name: {
                        $containsi: '',
                    },
                },
            ],
        },
        populate: ['agency'],
        pagination: {
            page: 1,
            pageSize: 25,
        },
    };
};

export default {
    namespaced: true,
    state: {
        transports: [],
        query: initialQuery(),
    },

    getters: {
        getTransportsList(state) {
            return state.transports;
        },
        getTransportsQuery(state) {
            return state.query;
        },
        getTransport(state) {
            return state.transport;
        },
    },
    mutations: {
        setTransportsList(state, transports) {
            state.transports = transports;
        },
        setTransportsQuery(state, query) {
            state.query = query;
        },
        resetTansportsQuery(state) {
            state.query = initialQuery();
        },
        setTransport(state, transportData) {
            state.transportData = transportData;
        },
        setTransportSearch(state, search) {
            console.log(state.query.filters);
            state.query.filters.$or[0].name.$containsi = search;
        },
    },

    actions: {
        fetchTransportList(ctx) {
            let _query = _.cloneDeep(ctx.state.query);

            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/mobility-transports?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchTransport(ctx, id, query = {}) {
            query.populate = ['trips.route', 'agency'];
            return new Promise((resolve, reject) => {
                axios
                    .get(`/mobility-transports/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        addTransport(ctx, transportData) {
            transportData = _.assign({}, transportData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/mobility-transports', { data: transportData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        updateTransport(ctx, data) {
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;

                axios
                    .put(`/mobility-transports/${id}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteTransport(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/mobility-transports/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchAgencies(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/mobility-agencies')
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
